import {
  getRedirectPath,
  cleanupRedirectPath,
  setRestrictedUserEmail,
} from '@/utils/auth';

export default defineNuxtRouteMiddleware(async (to) => {
  // Log the incoming route details
  // console.log('Auth callback triggered. Incoming route:', to.fullPath);

  const redirectPath = getRedirectPath() || '/';

  // Based on the internal Beta acceptance plan handle the redirects
  // Check for specific error in the query parameters
  if (to.query.error === 'access_denied') {
    if (
      to.query.error_description.includes('not been accepted yet') ||
      to.query.error_description.includes('more details about signing up')
    ) {
      // 'Redirecting to /beta due to access denial and not being accepted yet or they need to fill out survey for more details about signing up.
      const userEmail = to.query.error_description.split(
        'Your registration email - '
      )[1];

      if (userEmail) {
        setRestrictedUserEmail(userEmail);
      }
      return navigateTo('/beta-apply');
    }
  }

  cleanupRedirectPath();

  return navigateTo(redirectPath);
});
